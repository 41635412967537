import Breadcrumbs from "./Breadcrumbs";
import { FiChevronLeft } from "react-icons/fi";
import ContentList from "./ContentList";
import { useContentManager } from "../../contexts/ContentManagerContext";
import ContentButton from "./ContentButton";

export default function ContentNavigator() {
  const {
    collectionPath,
    goToPreviousCollection,
    newDocument,
    highlightActiveDoc,
  } = useContentManager();

  return (
    <div className="flex max-w-[500px] grow flex-col gap-4 rounded-lg bg-white p-8 shadow-light">
      <h3>Content Navigator</h3>

      <nav className="flex items-center gap-3">
        <FiChevronLeft
          className="cursor-pointer rounded-md text-2xl transition-all duration-300 hover:bg-bandera-azul-200 hover:text-bandera-azul-500"
          onClick={() => goToPreviousCollection()}
        />
        <Breadcrumbs path={collectionPath} />
      </nav>

      <ContentList />

      <ContentButton
        onClick={() => {
          highlightActiveDoc();
          newDocument();
        }}
        title="Agregar Documento"
      />
    </div>
  );
}
