export default function cleanAndFormatLegalText(input) {
  // Step 1: Normalize spaces and remove unwanted line breaks
  const cleanedText = input
    .replace(/-\n/g, "") // Remove hyphenated line breaks
    .replace(/\n\s+/g, " ") // Remove newlines within sentences and extra spaces
    .replace(/\s{2,}/g, " ") // Normalize multiple spaces to single space
    .trim();

  // Step 2: Split paragraphs using double newlines or numbered list markers
  const paragraphs = cleanedText.split(/\n{2,}|(?=\d+o\.\s)/);

  // Step 3: Format the text into paragraphs and list items
  const formattedText = paragraphs
    .map((para) => {
      // Identify numbered items (e.g., "1o.", "2o.") for ordered lists
      if (/^\d+o\.\s/.test(para)) {
        return `<li>${para.trim()}</li>`; // Wrap list items in <li>
      } else {
        return `<p>${para.trim()}</p>`; // Wrap regular text in <p>
      }
    })
    .join("");

  // Step 4: Wrap list items in <ol> if applicable
  const wrappedText = formattedText.includes("<li>")
    ? `<ol>${formattedText}</ol>` // Use <ol> for numbered lists
    : formattedText;

  return wrappedText;
}

