import { Link } from "react-router-dom";

export default function Button({ to, title }) {
  return (
    <Link
      to={to}
      className="bg-bandera-azul-200 hover:bg-bandera-azul-500 hover:text-white rounded-md py-2 px-4 text-center"
    >
      {title}
    </Link>
  );
}
