import ContentEditor from "./editor/ContentEditor";
import ContentNavigator from "./ContentNavigator";
import { ContentManagerProvider } from "../../contexts/ContentManagerContext";

export default function ContentManager() {
  return (
    <main className="flex h-full max-h-[90vh] w-full justify-center">
      <div className="flex w-full max-w-[1200px] gap-4 py-[2rem]">
        <ContentManagerProvider>
          <ContentNavigator />
          <ContentEditor />
        </ContentManagerProvider>
      </div>
    </main>
  );
}
