// layouts/DocumentLayout.js

import Document from "../components/Document/Document";
import TableOfContent from "../components/Document/TableOfContent";
import { useParams } from "react-router-dom";

import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../config/firebase";

export default function DocumentLayout() {
  const { documentId } = useParams();
  const documentRef = doc(db, "compendio", documentId);
  const [value, loading, error] = useDocumentData(documentRef);

  if (loading) return;
  if (error) return <div>Error: {error.message}</div>;
  if (!value) return <div>Documento no encontrado.</div>;

  const nextCollection = value?.subseccion;

  // Validate nextCollection before passing it
  const newPath = nextCollection
    ? `compendio/${documentId}/${nextCollection}`
    : null;

  return (
    <main className="flex h-full w-full flex-col items-center justify-center overflow-scroll">
      <div className="relative flex h-full max-w-[900px] flex-col gap-4 overflow-scroll sm:flex-row">
        {loading && <div>Loading Document...</div>}
        <TableOfContent path={newPath} />

        <div className="relative overflow-scroll py-6">
          <Document path={newPath} />
        </div>
      </div>
    </main>
  );
}
