import Button from "../components/ui/Button";

export default function Home() {
  return (
    <section className="flex w-full flex-col items-center p-4">
      <main className="flex max-w-[700px] flex-col gap-4">
        <header className="text-2xl font-light h-[30vh] flex items-center">
          <h1>
            Sistema Judicial Dominicano
            <br />
            Centralizado, Accesible & Libre
          </h1>
        </header>
        <div>
          <h2>Nuestra Misión</h2>
          <p>
            Desarrollar un espacio digital centralizado y robusto para el libre
            acceso a la información legal de la República Dominicana
          </p>
        </div>
        <div>
          <h2>Nuestra Misión</h2>
          <p>
            Una iniciativa <strong>completamente gratuita.</strong> Inspirada en
            ofrecer de manera organizada e interactiva una navegación sencilla.
            Creemos en los beneficios de las tecnologías modernas de Información
            y Comunicación (TICs) y soñamos con aprovecharlas al máximo
            ofreciendo este espacio de desarrollo social.
          </p>
        </div>
        <div className="text-center">
          <Button to="compendio" title={"Ver Contenido"} />
        </div>
      </main>
    </section>
  );
}
