import React, { createContext, useContext, useState, useEffect } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { db } from "../config/firebase";

const ContentManagerContext = createContext(null);

export function ContentManagerProvider({ children }) {
  // COLLECTIONS DATA HANDLING
  const [collectionPath, setCollectionPath] = useState(["compendio"]);
  const collectionRef = collection(db, ...collectionPath);
  const [collectionData, collectionLoading, collectionError] =
    useCollectionData(collectionRef, {
      idField: "id",
    });

  // DOCUMENTS DATA HANDLING
  const [documentId, setDocumentId] = useState(null);
  const [documentPath, setDocumentPath] = useState(null);
  const documentRef = documentPath ? doc(db, ...documentPath) : null;
  const [documentData, documentLoading, documentError] =
    useDocumentData(documentRef);
  const [activeDoc, setActiveDoc] = useState(null);

  // EMPTY DOCUMENT STATE
  const [addingDocument, setAddingDocument] = useState(false);

  // SYNCHRONIZE DOCUMENTPATH WITH COLLECTIONPATH
  useEffect(() => {
    setDocumentPath(null);
  }, [collectionPath]);

  // NAVIGATING COLLECTIONS
  function setNewCollectionPath(documentId, collectionId) {
    setCollectionPath([...collectionPath, documentId, collectionId]);
  }

  function goToPreviousCollection() {
    if (collectionPath.length > 1) {
      setCollectionPath((prevPath) => prevPath.slice(0, -2));
    } else {
      console.log("No previous collections.");
    }
  }

  // CHOOSING DOCUMENT
  function setNewDocumentPath(incommingDocumentId) {
    if (addingDocument) {
      setAddingDocument(false);
    }
    if (incommingDocumentId === documentId) {
      setDocumentPath(null);
      setDocumentId(null);
    } else {
      setDocumentPath([...collectionPath, incommingDocumentId]);
      setDocumentId(incommingDocumentId);
    }
  }

  function highlightActiveDoc(id) {
    if (id === activeDoc) {
      setActiveDoc(null);
    } else {
      setActiveDoc(id);
    }
  }

  // ADD A NEW DOCUMENT
  function newDocument() {
    if (documentPath || documentId) {
      setDocumentPath(null);
      setDocumentId(null);
    }
    setAddingDocument(true);
  }

  // SAVE NEW DOCUMENT

  async function saveNewDocument(docTitle, data) {
    if (!collectionPath || !docTitle) {
      console.error("Invalid collection path or document title.");
      return;
    }

    try {
      const docRef = doc(db, ...collectionPath, docTitle);

      // Check if the document exists
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        // Document exists, update it
        await updateDoc(docRef, data);
        console.log("Document successfully updated:", data);
      } else {
        // Document does not exist, create it
        await setDoc(docRef, data);
        console.log("Document successfully created:", data);
      }
    } catch (error) {
      console.error("Error in saveNewDocument:", error);
    }
  }

  // DELETE DOCUMENT
  function deleteDocument(docTitle) {
    const docRef = doc(db, ...collectionPath, docTitle);
    setDocumentPath(null);
    deleteDoc(docRef);
  }

  const value = {
    collectionPath,
    collectionData,
    collectionLoading,
    collectionError,
    documentPath,
    documentData,
    documentLoading,
    documentError,
    addingDocument,
    activeDoc,
    setNewCollectionPath,
    goToPreviousCollection,
    setNewDocumentPath,
    saveNewDocument,
    deleteDocument,
    newDocument,
    setAddingDocument,
    highlightActiveDoc,
  };

  return (
    <ContentManagerContext.Provider value={value}>
      {children}
    </ContentManagerContext.Provider>
  );
}

// Custom hook to use the ContentManagerContext
export function useContentManager() {
  const context = useContext(ContentManagerContext);
  if (!context) {
    throw new Error(
      "useContentManager must be used within a ContentManagerProvider",
    );
  }
  return context;
}
