import { NavLink } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

const NavBtn = ({ title, route }) => {
  return (
    <NavLink
      to={route}
      className={({ isActive }) =>
        `p-2 uppercase hover:font-bold ${isActive ? "active" : ""}`
      }
    >
      {title}
    </NavLink>
  );
};

export default function NavBar() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function handleOpenMenu() {
    setMenuIsOpen(!menuIsOpen);
  }

  return (
    <>
      <nav className="hidden gap-4 sm:flex">
        <NavBtn route="/" title="inicio" />
        <NavBtn route="sobre" title="sobre" />
        <NavBtn route="compendio" title="compendio" />
      </nav>
      <HiMenuAlt3
        className="h-full text-2xl sm:hidden"
        onClick={() => handleOpenMenu()}
      />

      <AnimatePresence>
        {menuIsOpen && (
          <motion.div
            className="absolute left-0 top-0 flex w-full justify-end bg-gray-300/20 backdrop-blur-[3px] sm:hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => handleOpenMenu()}
          >
            <motion.nav
              className="right-0 top-0 z-10 flex h-dvh w-[60%] flex-col gap-1 rounded-md bg-gray-100 text-right shadow-light"
              initial={{ x: 20 }}
              animate={{ x: 0 }}
              exit={{ x: 20 }}
            >
              <NavBtn route="/" title="inicio" />
              <NavBtn route="sobre" title="sobre" />
              <NavBtn route="compendio" title="compendio" />
              <div id="mobile-ToC"></div>
            </motion.nav>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
