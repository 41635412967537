export default function Breadcrumbs({ path }) {
  return (
    <ul className="flex w-full max-w-[24rem] gap-2 overflow-x-auto whitespace-nowrap uppercase">
      {path.map((item, index) => (
        <li key={index} className="flex items-center">
          {item}
          {index < path.length - 1 && <span className="mx-1">/</span>}
        </li>
      ))}
    </ul>
  );
}
