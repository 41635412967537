import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCg3ff07dSIkBmkX1_CUMAkZBiOqyKuahA",
  authDomain: "compendio-legal-rd.firebaseapp.com",
  projectId: "compendio-legal-rd",
  storageBucket: "compendio-legal-rd.appspot.com",
  messagingSenderId: "439760651619",
  appId: "1:439760651619:web:467d9483d5c10bdb387f56",
  measurementId: "G-96H2FH5PRS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initialize Firestore with offline persistence enabled
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});
