import logo from "../../assets/imgs/logo-compendio-legal-rd.svg";
import NavBar from "./NavBar";
import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <header className="sticky flex w-full justify-center p-4 top-0 bg-white z-30 border-b">
      <div className="flex max-w-[900px] grow justify-between">
        <NavLink to="/" className="flex items-center gap-4">
          <img src={logo} className="h-5" />
          <span className="font-black uppercase">Compendio Legal RD</span>
        </NavLink>
        <NavBar />
      </div>
    </header>
  );
}
