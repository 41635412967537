import { useState, useEffect } from "react";
import { useContentManager } from "../contexts/ContentManagerContext";
import formatNumberToThreeDigits from "../utils/formatNumberToThreeDigits";

export default function useContentEditor() {
  const {
    documentData,
    documentLoading,
    addingDocument,
    setAddingDocument,
    saveNewDocument,
    deleteDocument,
  } = useContentManager();

  const [id, setId] = useState("");
  const [tipo, setTipo] = useState("");
  const [numero, setNumero] = useState("");
  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [subseccion, setSubseccion] = useState("");

  const documentLoaded = documentData || addingDocument ? true : false;

  useEffect(() => {
    if (addingDocument) {
      resetForm();
    } else if (documentData) {
      setId(documentData.id || "");
      setTipo(documentData.tipo || "");
      setNumero(documentData.numero || 0);
      setTitulo(documentData.titulo || "");
      setTexto(documentData.texto || "");
      setSubseccion(documentData.subseccion || "");
    }
  }, [addingDocument, documentData]);

  function resetForm() {
    setId("");
    setTipo("");
    setNumero(0);
    setTitulo("");
    setTexto("");
    setSubseccion("");
  }

  function handleSubmit() {
    const payload = {
      id: `${tipo}-${formatNumberToThreeDigits(numero)}`,
      tipo,
      numero,
      titulo,
      texto,
      subseccion,
    };
    saveNewDocument(`${payload.id}`, payload);
    setAddingDocument(false);
  }

  return {
    documentLoading,
    id,
    tipo,
    numero,
    titulo,
    texto,
    subseccion,
    setTipo,
    setNumero,
    setTitulo,
    setTexto,
    setSubseccion,
    handleSubmit,
    deleteDocument,
    documentLoaded,
  };
}
