import ContentItem from "./ContentItem";
import { useContentManager } from "../../contexts/ContentManagerContext";
import { useState } from "react";

export default function ContentList() {
  const {
    collectionData,
    collectionLoading,
    collectionError,
    setNewCollectionPath,
    highlightActiveDoc,
    activeDoc,
  } = useContentManager();

  return (
    <ul className="content flex h-full flex-col overflow-scroll">
      {collectionLoading && "Cargando..."}
      {collectionError && "Error..."}
      {collectionData?.map((item) => (
        <ContentItem
          key={item.id}
          doc={item}
          goToSubcollection={() => {
            if (item.subseccion) {
              setNewCollectionPath(item.id, item.subseccion);
            } else {
              console.log("No hay subsecciones en este documento.");
            }
          }}
          highlight={activeDoc === item.id}
          highlightDoc={() => highlightActiveDoc(item.id)}
        />
      ))}
    </ul>
  );
}
