import { Link } from "react-router-dom";

export default function CompendioListItem({ code, title, to }) {
  return (
    <Link
      to={to}
      className="hover:bg-bandera-azul-400 block w-full rounded-md p-4 shadow-light hover:text-white transition-all duration-300"
    >
      <p className="font-black uppercase">{code}</p>
      <p className="up font-light">{title}</p>
    </Link>
  );
}
