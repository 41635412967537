import { forwardRef } from "react";
import ContentLabel from "./ContentLabel";

const ContentInput = forwardRef(
  ({ label, placeholder, value, onChange }, ref) => {
    return (
      <div className="flex flex-col">
        <ContentLabel label={label} />
        <div className="relative flex flex-col p-6">
          <input
            className="absolute left-0 top-0 h-full w-full rounded-md border-2 border-gray-400 p-3"
            type="text"
            name={label}
            id={label}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            ref={ref}
          />
        </div>
      </div>
    );
  },
);

export default ContentInput;
