import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../config/firebase";

export default function Documento({ path }) {
  const query = collection(db, path);
  const [docs, loading, error] = useCollectionData(query);

  if (loading) return <div>Loading Table of Content...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
      <ul className="max-w-[700px] px-2 text-[.8rem] font-light scroll-mt-[4rem]">
        {docs && docs.length > 0 ? (
          docs.map((doc, index) => (
            <div
              key={doc.tipo === "artículo" ? doc.id : doc.titulo}
              id={doc.tipo === "artículo" ? doc.id : doc.titulo}
            >
              <li className="cursor-pointer rounded-md px-2 py-1 hover:bg-bandera-azul-100">
                <p>
                  <span
                    className={`font-bold ${doc.tipo !== "artículo" ? "uppercase" : ""}`}
                  >
                    <span
                      className={`${doc.tipo === "artículo" ? "capitalize" : ""}`}
                    >
                      {doc.tipo}{" "}
                    </span>
                    {doc.numero} {doc.titulo}
                  </span>

                  <span
                    dangerouslySetInnerHTML={{ __html: doc.texto }}
                    className="space-y-2"
                  ></span>
                </p>
              </li>
              {doc.subseccion && doc.subseccion.length > 0 && (
                <Documento path={`${path}/${doc.id}/${doc.subseccion}`} />
              )}
            </div>
          ))
        ) : (
          <li>No contenido disponible.</li>
        )}
      </ul>
  );
}
