import { useState } from "react";
import { useContentManager } from "../../contexts/ContentManagerContext";

export default function ContentItem({
  doc,
  goToSubcollection,
  highlight,
  highlightDoc,
}) {
  const { setNewDocumentPath } = useContentManager();

  return (
    <div
      className={`flex rounded-md p-2 transition-all duration-300 ${
        highlight
          ? "bg-bandera-azul-400 text-white"
          : "text-black hover:bg-bandera-azul-100"
      } cursor-pointer`}
      onClick={() => {
        setNewDocumentPath(doc.id);
        highlightDoc(); 
      }}
    >
      <div className="grow">
        <div className="text-left">
          <span className="font-bold capitalize">
            {doc.tipo} {doc.numero}
          </span>
          
          {doc.titulo && (` - ${doc.titulo}`)}
        </div>

        {doc.subseccion && (

          <button
            className="ml-6 block hover:font-bold"
            onClick={(e) => {
              e.stopPropagation(); // Prevent parent click event
              goToSubcollection();
            }}
          >
            Ver {doc.subseccion}
          </button>
        )}
      </div>
    </div>
  );
}
