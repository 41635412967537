import CompendioListItem from "./CompendioListItem";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../config/firebase";
import { collection } from "firebase/firestore";

export default function CompendioList() {
  const query = collection(db, "compendio");
  const [data, loading, error] = useCollectionData(query);

  return (
    <section className="flex w-full flex-col items-center p-4">
      <main className="flex w-full max-w-[700px] flex-col gap-4">
        <h2>Seleccione un documento</h2>
        <p>A continuación el listado de documentos disponibles</p>

        <ul className="flex flex-col gap-4">
          {loading && "Cargando..."}
          {data &&
            data.map((doc, index) => (
              <li key={index}>
                <CompendioListItem
                  code={doc.id}
                  title={doc.titulo}
                  to={doc.id}
                />
              </li>
            ))}
        </ul>
      </main>
    </section>
  );
}
