import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import RootLayout from "./layout/RootLayout.jsx";
import Home from "./pages/Home.jsx";
import CompendioList from "./components/ui/CompendioList.jsx";
import DocumentLayout from "./layout/DocumentLayout.jsx";
import ContentManager from "./components/cms/ContentManager.jsx";
import About from "./pages/About.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "compendio",
        element: <CompendioList />,
      },
      {
        path: "compendio/:documentId",
        element: <DocumentLayout />,
      },
      {
        path: "cms",
        element: <ContentManager />,
      },
      {
        path: "sobre",
        element: <About />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
