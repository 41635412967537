import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../config/firebase";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";

export default function TableOfContentList({ path, onScrollTo }) {
  const query = collection(db, path);
  const [docs, loading, error] = useCollectionData(query);
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (key) => {
    setOpenSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  if (loading) return <div>Loading Table of Content...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <ul>
      <AnimatePresence initial={false}>
        {docs.map((doc) => {
          const key = doc.tipo === "artículo" ? doc.id : doc.titulo;
          const isOpen = openSections[key];

          return (
            <motion.div
              key={key}
              layout="position"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
              <li
                className="flex cursor-pointer items-center gap-4 rounded-md p-2 transition-all duration-300 hover:bg-bandera-azul-100"
                onClick={() => onScrollTo(key)}
              >
                <div className="w-full font-light">
                  <strong className="font-bold capitalize">
                    {doc.tipo} {doc.numero}
                  </strong>{" "}
                  <p>{doc.titulo}</p>
                </div>
                {doc.tipo !== "artículo" && (
                  <div className="flex h-6 w-6 items-center justify-center">
                    <FaChevronRight
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSection(key);
                      }}
                      className={`${isOpen ? "rotate-90" : ""} transition-transform`}
                    />
                  </div>
                )}
              </li>

              {/* Recursively render subsections */}
              <AnimatePresence initial={false}>
                {doc.subseccion && doc.subseccion.length > 0 && isOpen && (
                  <ExpandableSection>
                    <TableOfContentList
                      path={`${path}/${doc.id}/${doc.subseccion}`}
                      onScrollTo={onScrollTo}
                    />
                  </ExpandableSection>
                )}
              </AnimatePresence>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </ul>
  );
}

function ExpandableSection({ children }) {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  // Helper to force re-measuring content height
  const measureHeight = () => {
    requestAnimationFrame(() => {
      if (ref.current) {
        setHeight(ref.current.scrollHeight);
      }
    });
  };

  // Use MutationObserver to detect changes in children and re-measure
  useEffect(() => {
    const observer = new MutationObserver(measureHeight);
    if (ref.current)
      observer.observe(ref.current, { childList: true, subtree: true });

    return () => observer.disconnect(); // Clean up observer on unmount
  }, [children]);

  useLayoutEffect(() => {
    measureHeight(); // Measure initial height
  }, [children]);

  return (
    <motion.ul
      ref={ref}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height, opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="overflow-hidden pl-6"
    >
      {children}
    </motion.ul>
  );
}
