import TableOfContentList from "./TableOfContentList";
import { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { FaChevronRight } from "react-icons/fa";

export default function TableOfContent({ path }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  function handleIsOpen() {
    console.log("ver contenido");
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="top-0 hidden h-full w-full grow flex-col overflow-scroll rounded-md bg-white py-6 sm:sticky sm:flex sm:w-[300px]">
        <h3>Contenido</h3>
        <ul className="p-2 text-[.75rem] font-light">
          <TableOfContentList path={path} onScrollTo={handleScrollTo} />
        </ul>
      </div>

      <div
        className="mobile-toc sticky top-0 z-20 flex cursor-pointer flex-col items-center justify-center divide-y bg-white shadow-light transition-all duration-300 hover:bg-bandera-azul-200 sm:hidden"
        onClick={() => handleIsOpen()}
      >
        <div className="z-50 flex w-full items-center bg-white px-4 py-2">
          <p className="w-full text-center uppercase">Contenido</p>
          <FaChevronRight
            className={`${isOpen ? "rotate-90" : ""} transition-transform`}
          />
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="absolute top-full z-10 flex max-h-[80vh] w-full flex-col items-center justify-start overflow-scroll bg-white p-4 shadow-light transition-all duration-300"
              initial={{ height: 0 }}
              animate={{ height: "60vh" }}
              exit={{ height: 0 }}
              transition={{ duration: 0.4, ease: "linear" }}
            >
              <div className="overflow-scroll">
                <ul className="divide-y text-[.75rem] font-light">
                  <TableOfContentList path={path} onScrollTo={handleScrollTo} />
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
