import { useState } from "react";
import useContentEditor from "../../../hooks/useContentEditor";
import ContainerWhite from "../../ui/ContainerWhite";
import ContentInput from "./ContentInput";
import ContentLabel from "./ContentLabel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill styles
import cleanAndFormatLegalText from "../../../utils/cleanAndFormatTest";
import { useContentManager } from "../../../contexts/ContentManagerContext";

export default function ContentEditor() {
  const [content, setContent] = useState("");
  const { collectionLoading, collectionError, collectionData } =
    useContentManager();

  // This is here just to call the loading state
  if (collectionLoading) {
  }
  if (collectionData) {
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("Text");
    const formattedText = cleanAndFormatLegalText(pastedText);
    setContent(formattedText);
  };

  const {
    id,
    documentLoading,
    tipo,
    numero,
    titulo,
    texto,
    subseccion,
    setTipo,
    setNumero,
    setTitulo,
    setTexto,
    setSubseccion,
    handleSubmit,
    deleteDocument,
    documentLoaded,
  } = useContentEditor();

  return (
    <ContainerWhite>
      {documentLoading && <h3>Loading</h3>}

      {documentLoaded && (
        <>
          <h3>Editor {tipo}</h3>
          <form
            className="flex grow flex-col gap-4"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="flex w-full gap-2">
              <div className="w-[10rem]">
                <ContentInput
                  label="tipo"
                  placeholder="Tipo"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                />
              </div>
              <div className="w-[10rem]">
                <ContentInput
                  label="número"
                  placeholder="00-000"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                />
              </div>
              <div className="w-full">
                <ContentInput
                  label="título"
                  placeholder="Nombre del documento"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                />
              </div>
            </div>

            {tipo === "artículo" && (
              <div className="flex grow flex-col overflow-scroll">
                <ContentLabel label="texto" />
                <ReactQuill
                  value={texto}
                  onChange={setTexto}
                  className="flex h-full flex-col rounded-md"
                  onPaste={() => handlePaste()}
                />
              </div>
            )}

            {tipo !== "artículo" && (
              <div>
                <ContentInput
                  label="Subsección siguiente"
                  placeholder="Subsección"
                  value={subseccion}
                  onChange={(e) => setSubseccion(e.target.value)}
                />
              </div>
            )}

            <div className="flex gap-2">
              <button
                type="submit"
                className="grow rounded-md bg-bandera-azul-300 p-4"
              >
                Guardar
              </button>

              <button
                className="grow rounded-md bg-bandera-rojo-300 p-4"
                onClick={(e) => {
                  e.preventDefault();
                  deleteDocument(id);
                }}
              >
                Borrar <span className="capitalize">{tipo}</span>
              </button>
            </div>
          </form>
        </>
      )}
    </ContainerWhite>
  );
}
